import { CircularProgress } from "@mui/material";
import React, { Suspense } from "react";
import "./App.css";
const Home = React.lazy(() => import("./pages/Home"));
const PrivacyBar = React.lazy(() => import("./components/Dynamic/PrivacyBar"));

function App() {
  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <CircularProgress style={{ color: "#1e1919" }} />
          </div>
        }
      >
        <Home />
        <PrivacyBar />
      </Suspense>
    </>
  );
}

export default App;
